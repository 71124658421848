<template>
  <div>
    <!-- 列表页面 -->
    <div class="container" v-if="!showEdit">
      <div class="header">
        <div class="title">仪表列表</div>
      </div>
      <!-- 表格 -->
      <el-table :data="meters.items" style="width: 100%">
        <el-table-column
          prop="meter_name"
          label="设备名称">
        </el-table-column>
        <el-table-column
          prop="meter_code"
          label="设备编号">
        </el-table-column>
        <el-table-column
          prop="sn_no"
          label="设备SN">
        </el-table-column>
        <el-table-column
          prop="dev_eui"
          label="转换器地址">
        </el-table-column>
        <el-table-column
          prop="meter_addr"
          label="Modbus地址"
          >
        </el-table-column>
        <el-table-column
          prop="org_data"
          label="初始行码">
        </el-table-column>
        <el-table-column
          prop="current_degree"
          label="当前行码">
        </el-table-column>
        <el-table-column
          prop="used_degree"
          label="已用度数/吨数">
        </el-table-column>
        <el-table-column
          prop="union_meter_name"
          label="虚拟表">
        </el-table-column>
        <el-table-column
          prop="take_time"
          label="读数时间">
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="scope">
            <el-button plain size="mini" type="primary" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button
              plain
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
              v-permission="{ permission: '删除仪表', type: 'disabled' }"
            >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--    分页组件-->
      <page @page-event="pageEmit" :current-page="pageParam.currentPage"
            :total="meters.total" :page-size="meters.count"></page>
    </div>

  </div>
</template>

<script>
import { onMounted, ref,reactive } from 'vue'
import meterModel from '@/model/meter'
import Page from '@/component/custom/page'

export default {
  components: {
     Page
  },
  setup() {
    const meters = ref([])
    const loading = ref(false)
    const showEdit = ref(false)
    const pageParam = reactive({
      currentPage: 1,
      page : 0,
      count : 10
    })


    onMounted(() => {
       getMeters({ page:pageParam.page , count:pageParam.count })
    })

    const getMeters = async ({page,count}) => {
      try {
        loading.value = true
        meters.value = await meterModel.getMeters({ page, count })
        loading.value = false
      } catch (error) {
        loading.value = false
        if (error.code === 10020) {
          meters.value = []
        }
      }

    }

    const pageEmit = async (page) => {
      console.log(`当前页：${page}`)
      const reqPage = page-1;
      console.log(`传入参数接口页: ${reqPage}` )
      pageParam.currentPage = page
      await getMeters({page:reqPage, count:meters.value.count})
    }

    const indexMethod = index => index + 1

    return {
      meters,
      indexMethod,
      showEdit,
      pageEmit,
      pageParam
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 30px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    .title {
      color: $parent-title-color;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }
}
</style>
